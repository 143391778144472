import React from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Footer from "../components/layouts/Footer";
import Herosection from "../components/sections/Herosection";

function Homepage() {
  document.body.classList.add("dark");
  return (
    <>
      {/* <Header logoSource="/images/logo.svg" /> */}
      <main className="content-3">
        <Element name="section-home">
          <ReactCursorPosition>
            <Herosection />
          </ReactCursorPosition>
        </Element>
        {/* <Element name="section-about">
          <About />
        </Element> */}
        {/* <Element name="section-blogs">
          <Blogs />
        </Element> */}
        {/* <Element name="section-contact">
          <Contact />
        </Element> */}
        <div className="spacer" data-height="96"></div>
      </main>
      <Footer />
    </>
  );
}

export default Homepage;
